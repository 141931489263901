import Markdown from 'markdown-it';
import Turndown from 'turndown';

function removeBackticks(content: string): string {
  if (content.startsWith('`')) {
    content = content.substring(1, content.length);
  }

  if (content.endsWith('`')) {
    content = content.substring(0, content.length - 1);
  }

  return content;
}

export class SlackMarkdown {
  public markdown = new Markdown({
    breaks: true,
    linkify: true,
  });
  public turndown = new Turndown({
    bulletListMarker: '•',
    emDelimiter: '_',
    strongDelimiter: '**',
  });

  constructor() {
    this.turndown.addRule('img', {
      filter: ['img'],
      replacement: function (_content, node) {
        return node.getAttribute('alt');
      },
    });
    this.turndown.addRule('strikethrough', {
      filter: ['del', 's', 'strike'],
      replacement: function (content) {
        return '~~' + content + '~~';
      },
    });
    this.turndown.addRule('mention', {
      filter: ['span'],
      replacement: function (content, node, options) {
        if (node.hasAttribute('data-id')) {
          const slackId = node.getAttribute('data-id');
          const prefix = slackId[0] === 'S' ? '!subteam^' : '@';
          return `<${prefix}${slackId}>`;
        }
        return content;
      },
    });
    this.turndown.addRule('pre', {
      filter: ['pre'],
      replacement: function (content) {
        return '```\n' + removeBackticks(content) + '\n```';
      },
    });
    this.turndown.addRule('code', {
      filter: ['code'],
      replacement: function (content) {
        return '`' + content + '`';
      },
    });
  }

  toHtml(text: string) {
    return this.markdown.renderInline(text);
  }

  toMd(text: string) {
    return this.turndown.turndown(text);
  }
}
